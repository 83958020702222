textarea {
    border-radius: 4px;
    border: 1px solid #c4c4c4;
    padding: 1rem;
    font-family: monospace;
}
.multi-option-input {
    svg {
        display: none;
    }
}
.allChecked {
    border: 1px solid #0d6efd;
    border-radius: 5px;
}
.submit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}