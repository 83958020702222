.filter-by-phase {
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding-left: 0.5rem;
  .MuiFormControlLabel-root {
    padding-right: 12px;
    background: #f5f5f5;
    border-radius: 30px;
  }
  .MuiButtonBase-root {
    display: flex;
    align-items: center;
    padding-right: 5px;
  }
  .MuiTypography-root {
    font-size: 13px;
    white-space: nowrap;
  }
  .MuiButtonBase-root {
    & + span {
      opacity: 0.6;
    }
  }
  .MuiButtonBase-root.Mui-checked {
    & + span {
      opacity: 1;
    }
  }
}
