.card-wrapper {
    gap: 2rem;
    padding-bottom: 2rem;
    justify-content: flex-start!important;
    .card-link {
        color: #6c757d;
    }
}

.card {
    width: calc(50% - 1rem);
    // padding: 0 1rem;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    box-shadow: 1px 1px 3px 0px rgba(51, 51, 51, 0.05);
    border-color: #0000001b;
    cursor: pointer;
    &:hover {
        border-color: #3333333a;
        box-shadow: 1px 1px 7px 0px rgba(51, 51, 51, 0.1);
        .card-link {
            color: #0d6efd;
            svg {
                margin-left: 8px;
                color: #0d6efd;
            }
        }
    }
    .card-title {
        text-transform: uppercase;
    }
    .card-icon {
        padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
        margin-top: 1rem;
        svg {
            font-size: 30px;
        }
        &.cluster-icon {
            background-color: #00968829;
            border-radius: 4px;
            svg {
                color: #009688;
            }
        }
        &.images-icon {
            background-color: #2196f326;
            border-radius: 4px;
            svg {
                color: #2196f3;
            } 
        }
        &.queries-icon {
            background-color: #9c27b026;  
            border-radius: 4px;
            svg {
                color: #9c27b0;
            }
        }
        &.queue-icon {
            background-color: #a158ad26;  
            border-radius: 4px;
            svg {
                color: #4f0d5b;
            }
        }
        &.comparassion-icon {
            background-color: #ff980026;  
            border-radius: 4px;
            svg {
                color: #ff9800;
            }
        }
        &.history-icon {
            background-color: #00000010;  
            border-radius: 4px;
            // svg {
            //     color: #007bff;
            // }
        }
        &.validation-icon {
            background-color: #664d0342;  
            border-radius: 4px;
            svg {
                color: #664d03;
            }
        }
        &.deploy-icon {
            background-color: #00ffb32d;  
            border-radius: 4px;
            svg {
                color: #00ffb3;
            }
        }

    }
    .card-text {
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.9rem;
    }
    .card-link {
        text-transform: uppercase;
        text-decoration: none;
        display: flex;
        align-items: center;
        svg {
            margin-left: 3px;
            font-size: 28px;
            transition: all 0.2s;
            color: #3333336d;
        }
    }
}