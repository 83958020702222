.git-details {
    b {
        text-transform: uppercase;
        min-width: 150px;
        display: inline-block;
    }
}
.urls-accordion {
    ul {
      list-style: circle;
      li {
        font-size: 0.9rem;
        margin-bottom: 5px;
      }
    }
    h2 {
      margin-bottom: 0;
    }
  }