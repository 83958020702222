.deployment-wrapper {
  // margin-top: 2rem;
  h2 {
    display: flex;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 1rem;
    span {
      &:last-child {
        margin-left: auto;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
    small {
      margin-left: 0.5rem;
      font-size: 18px;
    }
  }
  thead {
    tr {
      th {
        label {
          display: block;
        }
      }
    }
  }
  tbody {
    tr {
      td:last-child {
        text-align: center;
        vertical-align: middle;
      }
      td:nth-last-child(2),
      td:nth-last-child(3),
      td:nth-last-child(1) {
        text-align: center;
        vertical-align: middle;
      }
    }
  }
  .urls-accordion {
    ul {
      list-style: circle;
      li {
        font-size: 0.9rem;
        margin-bottom: 5px;
      }
    }
    h2 {
      margin-bottom: 0;
    }
  }
}
.accordion-header {
  svg {
    font-size: 20px;
    margin-right: 7px;
  }
}