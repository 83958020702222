.compare-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem;
    .first-env, .second-env {
        width: 50%;
    }
    .form-floating {
        margin-bottom: 1rem;
    }
}
.compare-btn {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }
}
.additional-options {
    h4 {
        text-align: center;
        margin-top: 1rem;
    }
    .additional-options-checkboxes {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        .checks-wrapper {
            display: flex;
            align-items: center;
        }
    }
}