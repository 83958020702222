.back-btn {
    display: flex;
    align-items: center;
    padding: 0;
    background: none!important;
    border: none;
    margin-bottom: 5px;
    &:hover {
        opacity: 0.7;
    }
    svg {
        margin-right: 7px;
    }
}
.special {
    border: 1px solid black;
}

.deleted {
    color: red;
}
.pod-icon-wrap {
    position: relative;
    
    width: 15px;
    height: 15px;

    display: inline-block;
    vertical-align: middle;
    line-height: 1;

    small {
        color: white;
        position: absolute;
        font-size: 9px;
        width: 15px;
        height: 15px;
        /* z-index: 9; */
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
    }
}