@media only screen and (max-width: 945px) {
  .navbar {
    padding-bottom: 1rem;
}
  .page-wrapper {
    flex-direction: column;
    .navbar {
      height: 55px;
      .container-fluid {
        flex-direction: row;
        align-items: center!important;
        .navbar-nav {
          margin-top: unset!important;
        }
      }
    }
  }
  nav {
    .container-fluid {
      .mobile-menu {
        display: block;
        min-width: 75px;
        button {
          padding: 0 5px;
        }
        svg {
          color: white;
          font-size: 20px;
          cursor: pointer;
        }
      }
      .brand-and-pages-wrapper {
        flex-direction: row-reverse;
        flex: 1;
        justify-content: center;
        .navbar-brand {
          margin: 0!important;
          a {
            margin: 0;
          }
          span {
            display: none;
          }
        }
        & + .ms-auto.navbar-nav {
          min-width: 75px;
        }
      }
      .nav-pages {
        position: absolute;
        top: 55px;
        left: 0;
        margin: 0;
        flex-direction: column;
        background-color: #1452ad;
        width: 100%;
        padding: 1rem;
        align-items: flex-start;
        .ml-auto.navbar-nav {
            margin: 0;
            a.nav-link {
                height: 32px;
                line-height: 1.4;
            }
        }

      }
    }
  }
}
@media only screen and (max-width: 767px) {
    .container {
      min-height: calc(100vh - 103px);
    }
    .card-wrapper {
        display: flex;
        flex-direction: column;
        margin: 0!important;
        .card {
            width: 100%;
        }
    }
    .output-options {
      flex-direction: column-reverse;
      .col-8 {
        width: 100%;
        margin-top: 1rem;
      }
    }
    .additional-options-checkboxes {
      flex-direction: column;
      align-items: flex-start!important;
      gap: 0!important;
      .checks-wrapper {
          display: flex;
          align-items: center;
      }
  }
}
@media only screen and (max-width: 576px) {
  .container, .container-sm {
    max-width: unset;
  }
}