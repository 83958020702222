.green-result {
  background: rgba(26, 255, 0, 0.1);
}

.yellow-result {
  background: rgba(229, 243, 23, 0.1);
}

.red-result {
  background: rgba(235, 29, 56, 0.1);
}

.row-wrapper {
  & + .row {
    margin: 0;
    td {
      pre {
        padding: 0 10px;
        word-break: break-word;
        line-height: initial;
      }
    }
  }
}
