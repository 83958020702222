.navbar {
  background-color: #1452ad;
  position: sticky;
  top: 0;
  z-index: 999;
  .mobile-menu {
    display: none;
  }
  .brand-and-pages-wrapper {
    display: flex;
    align-items: center;
  }
  .nav-pages {
    display: flex;
    align-items: center;
  }
  .container-fluid {
    padding: 0 8px;
    .navbar-brand {
      cursor: pointer;
      // margin-right: 2rem;
      // border: 1px solid #ffffff63;
      // border-radius: 2px;
      // padding: 0 10px;
      display: flex;
      align-items: center;
      font-family: monospace;
      font-size: 16px;
      text-transform: uppercase;
      a {
        display: flex;
        align-items: center;
        span.logo-sr {
          border: 1px solid #ffffff63;
          width: 35px;
          height: 31px;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          font-size: 20px;
          font-family: monospace;
        }
        span {
          font-weight: normal;
        }
      }

    }
    .navbar-nav {
      margin-right: 1rem;
      a {
        font-size: 14px;
        padding: 0 !important;
        display: flex;
        align-items: center;
        height: 20px;
        line-height: 20;
        overflow: hidden;
        svg {
          font-size: 20px;
          margin-right: 5px;
        }
      }
    }
    .navbar-nav .nav-link.active,
    .navbar-nav .show > .nav-link {
      color: var(--bs-nav-link-color);
    }
    .active-nav {
      font-weight: bold;
      color: var(--bs-navbar-active-color) !important;
    }
  }
}
