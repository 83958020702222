.react-bootstrap-table,
table {
  border: 1px solid #c8ccd1;
  border-radius: 5px;
  overflow: hidden;
}
.react-bootstrap-table {
  margin-bottom: 1rem;
  table {
    margin-bottom: 0;
  }
}
thead {
  font-size: 11px;
  tr {
    th {
      cursor: pointer;
      color: #525252;
      &:hover {
        color: black;
      }
    }
  }
  tr:first-child {
    background: #dee2e6;
  }
}

table {
  th {
    vertical-align: middle;
  }
}

.filter-label {
  span.sr-only {
    display: none;
  }
}

.page-wrapper .container {
  overflow: auto;
  display: flex;
  flex-direction: column;
  h2 {
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.filter-label {
  margin-top: 3px;
  display: block;
}

.order-4 {
  margin-right: 7px;
  border-radius: 4px;
  /* background-color: rgb(236, 239, 241); */
  padding: 0px 5px 3px;
  display: inline-block;
  line-height: 1;
  position: relative;
  top: -2px;
  opacity: 0.4;

  &::after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }

  &::before {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    transform: rotate(180deg);
  }
}

.caret-4-desc {
  margin-right: 7px;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  position: relative;
  top: -2px;
}

.caret-4-asc {
  margin-right: 7px;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  position: relative;
  top: -2px;
  transform: rotate(180deg);
}

.page-item .page-link {
  color: #6c757d !important;
}

.active > .page-link,
.page-link.active {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.active.page-item {
  color: #6c757d !important;

  a {
    color: #fff !important;
  }
}

.react-bootstrap-table-pagination {
  justify-content: flex-start!important;
  .col-md-6.col-xs-6.col-sm-6.col-lg-6 {
    max-width: 150px;
  }
}

.react-bs-table-sizePerPage-dropdown {
  .dropdown-menu {
    bottom: 30px;
  }

  #pageDropDown::after {
    transform: rotate(180deg);
  }
}
.bootstrap-table-wrapper, .react-bootstrap-table {
  min-width: 850px;
}
table {
  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('../public/assets/img/down-arrow.svg') no-repeat right center;
    // padding-right: 20px; /* Adjust the padding as needed */
    background-size: 13px;
    background-position: center right 5px;
    min-width: 150px;
  }
  select::-ms-expand {
    display: none; /* Hide the default arrow on IE10 and IE11 */
  }
  thead {
    tr {
      th {
        .filter-label {
          input, select {
            max-width: 250px;
            border-color: #bfbfbf;
            margin-left: -1px;
            font-size: 12px;
          }
        }
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        font-size: 0.9rem;
        button {
          &:hover {
            opacity: 0.7;
          }
          svg {
            font-size: 20px;
          }
        }
      }
    }
  }
}

// .react-bs-table-pagination .dropdown .dropdown-menu {
//     top: auto;
//     bottom: 100%;
//     margin-bottom: 2px;
// }
// @media (min-width: 1400px) {
// .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
//     max-width: 1500px;
// }
// }

.page-wrapper {
    display: flex;
    nav {
      & + div.container {
        .row {
        justify-content: center;
      }
    }
  }
  .navbar .container-fluid {
    flex-direction: column;
    align-items: flex-start!important;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    .navbar-brand {
      margin: 1rem 1rem 1rem 0.6rem;
      padding: 0;
    }
  } 
  .brand-and-pages-wrapper {
    flex-direction: column;
    align-items: flex-start!important;
    flex: 1 1 auto;
    & + .navbar-nav {
      margin-left: 1rem;
    }
  }
  .nav-pages {
    flex-direction: column;
    align-items: flex-start!important;
    margin: 2rem 0 1rem 1rem;
    svg {
      margin-right: 10px!important;
    }
    .validation, .deploy-page {
      svg {
        path {
          stroke: #ffffff8c;
        }
      }
    }
    .navbar-nav {
      margin-bottom: 0.9rem;
      text-transform: uppercase;
    }
  }
}
.navbar {
    position: sticky;
    top: 0;
    height: 100vh;
}