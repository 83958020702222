.deploy-page {
  .deploy-btn {
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: white;
      margin-right: 7px;
      path {
        stroke: white;
      }
    }
  }
  .align-baseline {
    word-break: break-word;
  }
}
